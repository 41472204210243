import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import DocumentViewer from "../../components/DocQuery/DocumentViewer";
import ChatInterface from "../../components/DocQuery/ChatInterface";
import { useOutletContext, useParams } from "react-router-dom";
import ConditionalRenderer from "../../components/ConditionalRenderer";

function Layout({ isLoading }) {
  const { id } = useParams();
  const { setId } = useOutletContext();

  useEffect(() => {
    setId(id);
  }, [id]);

  return (
    <div className="h-[100vh] w-full background-gradient relative">
      <div className="h-full mx-auto max-w-[1680px] p-4">
        <ConditionalRenderer
          defaultComponent={() => (
            <div className="w-full h-[87vh] flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
          condition={!isLoading}
        >
          <div className="flex flex-1 h-full">
            <div className="w-[500px] xl:w-[600px] relative">
              <DocumentViewer />
            </div>
            <div className="flex-1">
              <ChatInterface id={id} />
            </div>
          </div>
        </ConditionalRenderer>
      </div>
    </div>
  );
}

export default Layout;
